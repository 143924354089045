.moe-main-class {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.moe-wrap {
  position: absolute;
  width: 315px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.moe-content {
  padding: 40px 18.5px 24px;
  border-radius: 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
}
.content-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
}
.text-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.main-text {
  padding: 0;
  margin: 0;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.sec-text {
  padding: 0;
  margin: 0;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}
button {
  outline: none;
  border: 0;
}
.btn-allow {
  display: flex;
  width: 232px;
  height: 48px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25),
    0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset;
  color: #fff;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.btn-block {
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  background-color: rgba(0, 0, 0, 0);
}
